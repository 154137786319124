<template>
  <!-- <b-card-code title="Column Search Table"> -->
    <div class="card mb-5 mb-xl-10">
      <div class="card-body">
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="dataMapping"
      :rtl="direction"
      :line-numbers="true"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <!-- <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div> -->

        <!-- Column: Status -->
        <span v-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'jawaban'">
          <span v-if="props.row.jawaban === null">
            {{ props.row.jawaban }}
          </span>
          <span v-else>
            {{props.row.jawaban.substring(0,50)+".."}}
          </span>
          
          
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="showModal(props.row)">
                <!-- <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                /> -->
                <span>Mapping</span>
              </b-dropdown-item>
              <b-dropdown-item @click="lihatPertanyaan(props.row)">
                <!-- <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                /> -->
                <span>Lihat Contoh Pertanyaan</span>
              </b-dropdown-item>

              <b-dropdown-item @click="lihatCatatan(props.row)">
                <!-- <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                /> -->
                <span>Lihat Catatan</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  
  <b-modal ref="my-modal" hide-footer title="Mapping Expert" size="lg">
      <b-container fluid>
        <b-row class="my-1">
          <b-col sm="3">
            <label>Expert</label>
          </b-col>
          <b-col sm="9">
            <select class="form-control" v-model="dataPertanyaan.expert">
              <option v-for="option in dataExpert" :key="option.nama">
                {{ option.nama }}
              </option>
            </select>
          </b-col>
        </b-row>
        <br>
        <b-card>
        <div class="row">
          <div class="col-md-12">
            <strong>Nama Cluster:</strong>
          </div>
          <div class="col-md-12">{{dataPertanyaan.nama_cluster}}</div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <strong>Jawaban:</strong>
          </div>
          <div class="col-md-12">{{dataPertanyaan.jawaban}}</div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <strong>Status:</strong>
          </div>
          <div class="col-md-12">{{dataPertanyaan.status}}</div>
        </div>
        </b-card>
      </b-container>
      <div class="float-right">
          <b-button  variant="outline-danger" style="margin-right:10px" @click="hideModal">Batal</b-button>
          <b-button variant="primary"  @click="ubah">Simpan</b-button>
      </div>
  </b-modal>

  <b-modal ref="my-modalPertanyaan" hide-footer title="Daftar Pertanyaan" size="lg">
      
        <ul class="list-group" >
          <li class="list-group-item" v-for="pertanyaan in dataPertanyaanByCluster" :key="pertanyaan">{{pertanyaan.pertanyaan}}</li>
        </ul>
        <br>
    
      <div class="float-right">
          <b-button  variant="outline-danger" style="margin-right:10px" @click="hideModalPertanyaan">Tutup</b-button>
      </div>
  </b-modal>

  <b-modal ref="my-modalCatatan" hide-footer title="Daftar Catatan" size="lg">
      
        <b-card v-for="catatan in dataCatatan" :key="catatan" :title="'Catatan dari: '+catatan.asal" :sub-title="catatan.catatan"></b-card>
        <br>
    
      <div class="float-right">
          <b-button  variant="outline-danger" style="margin-right:10px" @click="hideModalCatatan">Tutup</b-button>
      </div>
  </b-modal>

    </div>
    </div>
  <!-- </b-card-code> -->
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard,BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BFormTextarea,BFormFile,BButton,BFormDatepicker
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeColumnSearch } from './code'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    quillEditor,
    BFormFile,
    BButton,
    BFormDatepicker,
    Ripple
  },
  setup(){
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Jawaban',
    }
    return {
      editorOption
    }
  },
  data() {
    return {
      userData : JSON.parse(localStorage.getItem('userData')),
      url: localStorage.getItem('baseapi'),
      name: '',
      nameState: null,
      submittedNames: [],
      pageLength: 10,
      dir: false,
      codeColumnSearch,
      columns: [
        {
          label: 'Nama Cluster',
          field: 'nama_cluster',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Nama Cluster',
          },
        },
        {
          label: 'Expert',
          field: 'expert',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Expert',
          },
        },
        {
          label: 'Jawaban',
          field: 'jawaban',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Jawaban',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      dataMapping:[],
      dataPertanyaan:{},
      dataExpert:[],
      dataPertanyaanByCluster:[],
      dataCatatan:[],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.loadData()
    // this.$http.get('/good-table/basic')
    //   .then(res => { this.rows = res.data })
  },
  methods: {
    loadData(){
      axios.get(this.url+"cluster",
      {
        headers:{
          xth: this.userData.token
        }
      }).then(response =>{
        this.dataMapping = response.data.data
        console.log('datamapping')
        console.log(this.dataMapping)
      }).catch(error=>{
        if (error.response.data === 'Token Tidak Valid/Hak akses tidak sesuai') {
          this.$swal({
            title: error.response.data,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
            }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem('userData')
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            }
          })
        } else {
         this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: error.response.data,
            variant: 'danger',
          },
        })
        }
      })

      axios.get(this.url+"user?filter=hak_akses,=,expert",{
        headers:{
          xth: this.userData.token
        }
      }).then(response =>{
        this.dataExpert = response.data.data
        console.log(this.dataExpert)
      })
      
    },
    showModal(data) {
        this.$refs['my-modal'].show()
        this.dataPertanyaan = data
        console.log(data)
    },
    hideModal() {
        this.$refs['my-modal'].hide()
    },
    hideModalPertanyaan() {
        this.$refs['my-modalPertanyaan'].hide()
    },
    hideModalCatatan() {
        this.$refs['my-modalCatatan'].hide()
    },
    ubah(){
       axios.post(this.url+"cluster/"+this.dataPertanyaan.id,
      {
        expert: this.dataPertanyaan.expert
      },{
        headers:{
          xth: this.userData.token
        }
      }).then(response =>{
        this.hideModal()
        this.makeToast("success","Berhasil diubah")
        this.loadData()
      }).catch(response =>{
        this.hideModal()
        this.makeToast("warning","Gagal diubah")
        this.loadData()
      })
    },
    lihatPertanyaan(data){
      this.$refs['my-modalPertanyaan'].show()
      axios.get(this.url+"pertanyaan_cluster?filter=cluster,=,"+data.nama_cluster,
      {
        headers:{
          xth: this.userData.token
        }
      }).then(response =>{
        this.dataPertanyaanByCluster = response.data.data
      })
    },
    lihatCatatan(data){
      this.$refs['my-modalCatatan'].show()
      axios.get(this.url+"catatan_cluster?filter=cluster_id,=,"+data.id,
      {
        headers:{
          xth: this.userData.token
        }
      }).then(response =>{
        this.dataCatatan = response.data.data
      })
    },
    makeToast(variant = null,pesan) {
      this.$bvToast.toast(pesan, {
        title: `${variant || 'default'}`,
        variant,
        solid: false,
      })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
<style lang="scss" scoped>
form ::v-deep {
  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>